<template>
  <div class='wrap'>
    <div class='wrap-header'>
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/declaration' }">Pre-declaration </el-breadcrumb-item>
        <el-breadcrumb-item>Check Submit Status</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class='wrap-input'>
      <el-input v-model="inputSearch" prefix-icon='el-icon-search' class='input-search' @input="judgeSearch" @keyup.native.enter="search" clearable></el-input>
      <el-select v-model="queryCriteria" placeholder='choose...' @change="queryByTime">
        <i slot='prefix' class='el-input__icon el-icon-date'></i>
        <el-option
          v-for="item in criteriaOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class='wrap-content'>
      <el-table
        :data="loadData"
        :row-class-name="errorStatus"
        :cell-class-name="errorStatusCol"
        class='wrap-content-table'>
        <el-table-column
          prop='uld'
          label='ULD#'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          prop='mawb'
          label='MAWB#'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          prop='hawb'
          label='HAWB#'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          prop='ediType'
          label='EDI Type'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          prop='status'
          label='Status'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          prop='submitTime'
          label='Submit Time'
          label-class-name='title'>
        </el-table-column>
        <el-table-column
          label='Remarks'
          class-name='remarks-cell'
          label-class-name='title'>
          <template slot-scope='scope'>
            <el-popover
              placement='bottom-end'
              title=''
              trigger='click'>
              <div><p v-for="(item, index) in judgeFormat(scope.row.remarks)" :key="index" style="margin:0">{{ item }}</p></div>
              <span slot='reference' class='remarks-width'>{{ scope.row.remarks }}</span>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='pagination'>
      <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes='[10, 20, 30, 40]'
          :page-size="pageSize"
          layout='total, prev, slot, next, sizes, jumper, ->'
          :total="total">
          <span class='on-pager'>{{ currentPage }}</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryCheckSubmitStatusByTIme } from '@/api/declaration/declaration-api.js'
import { getOfficeCode } from '@/utils/auth'

export default {
  name: 'check',
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      inputSearch: null,
      loadData: [],
      queryCriteria: -3,
      criteriaOptions: [
        {
          value: 0,
          label: 'Today'
        },
        {
          value: -1,
          label: 'Yesterday'
        },
        {
          value: -3,
          label: 'Past 3 days'
        },
        {
          value: -7,
          label: 'Past 7 days'
        },
        {
          value: -30,
          label: 'Last month'
        }
      ],
      mawb: '',
      uld: ''
    }
  },
  mounted () {
    this.initCheck()
  },
  methods: {
    initCheck () {
      const params = {
        officeCode: getOfficeCode(),
        timeGap: this.queryCriteria,
        pageNum: 1,
        pageSize: 10,
        mawb: this.mawb,
        uld: this.uld
      }
      queryCheckSubmitStatusByTIme(params).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            this.loadData.push({
              uld: element.unitLoadDevice,
              mawb: element.mawbNo,
              hawb: element.hawbNo,
              ediType: element.ediType,
              status: element.result === 'success' ? 'SUCCESS' : 'FAIL',
              message: element.message,
              submitTime: element.submitDtLoc,
              remarks: element.message,
              rclAvailable: element.rclAvailable
            })
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    errorStatus ({ row }) {
      if (row.status === 'FAIL') return 'error-row'
      else if (row.status === 'SUCCESS') return 'success-row'
      return ''
    },
    errorStatusCol ({ row, columnIndex }) {
      const messsage1 = 'Successfully submit to CTO'
      const messsage2 = 'RCL is available.'
      const messsage3 = 'Please do not submit data repeatedly!'
      const messsage4 = 'Data has been submitted to HKIA successfully!'
      if (row.status === 'SUCCESS' && columnIndex === 6) {
        return row.message.slice(0, 26) === messsage1 || row.message === messsage2 || row.message === messsage3 || row.message === messsage4 ? '' : 'error-row'
      }
      return ''
    },
    judgeDisabled (item) {
      if (item instanceof Array) return false
      else if (item.length > 20) return false
      else return true
    },
    judgeFormat (item) {
      if (item instanceof Array) return item
      else {
        let itemArray = []
        itemArray.push(item)
        return itemArray
      }
    },
    sizeChange (val) {
      this.pageSize = val
      const params = {
        officeCode: getOfficeCode(),
        timeGap: this.queryCriteria,
        pageNum: 1,
        pageSize: val,
        mawb: this.mawb.toUpperCase(),
        uld: this.uld.toUpperCase()
      }
      queryCheckSubmitStatusByTIme(params).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            this.loadData.push({
              uld: element.unitLoadDevice,
              mawb: element.mawbNo,
              hawb: element.hawbNo,
              ediType: element.ediType,
              status: element.result === 'success' ? 'SUCCESS' : 'FAIL',
              message: element.message,
              submitTime: element.submitDtLoc,
              remarks: element.message,
              rclAvailable: element.rclAvailable
            })
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    currentChange (val) {
      const params = {
        officeCode: getOfficeCode(),
        timeGap: this.queryCriteria,
        pageNum: val,
        pageSize: this.pageSize,
        mawb: this.mawb.toUpperCase(),
        uld: this.uld.toUpperCase()
      }
      queryCheckSubmitStatusByTIme(params).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            this.loadData.push({
              uld: element.unitLoadDevice,
              mawb: element.mawbNo,
              hawb: element.hawbNo,
              ediType: element.ediType,
              status: element.result === 'success' ? 'SUCCESS' : 'FAIL',
              message: element.message,
              submitTime: element.submitDtLoc,
              remarks: element.message,
              rclAvailable: element.rclAvailable
            })
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    queryByTime () {
      this.inputSearch = ''
      this.mawb = ''
      this.uld = ''
      this.initCheck()
    },
    search () {
      if (this.inputSearch.length === 0) {
        this.uld = ''
        this.mawb = ''
        this.searchService()
      } else {
        const pattern = /^[0-9]{3}[-][0-9]{8}$/
        if (pattern.test(this.inputSearch) !== true) {
          this.uld = this.inputSearch
          this.mawb = ''
        } else {
          this.uld = ''
          this.mawb = this.inputSearch
        }
        this.searchService()
      }
    },
    judgeSearch () {
      if (this.inputSearch.length === 0) {
        this.uld = ''
        this.mawb = ''
        this.searchService()
      }
    },
    searchService () {
      const params = {
        officeCode: getOfficeCode(),
        timeGap: this.queryCriteria,
        pageNum: 1,
        pageSize: 10,
        mawb: this.mawb.toUpperCase(),
        uld: this.uld.toUpperCase()
      }
      queryCheckSubmitStatusByTIme(params).then(res => {
        if (res.data.CODE === 200) {
          this.loadData = []
          const result = res.data.DATA
          result.records.forEach(element => {
            this.loadData.push({
              uld: element.unitLoadDevice,
              mawb: element.mawbNo,
              hawb: element.hawbNo,
              ediType: element.ediType,
              status: element.result === 'success' ? 'SUCCESS' : 'FAIL',
              message: element.message,
              submitTime: element.submitDtLoc,
              remarks: element.message,
              rclAvailable: element.rclAvailable
            })
          })
          this.currentPage = result.pageNum
          this.pageSize = result.pageSize
          this.total = result.total
        } else {
          if (res.data.MESSAGE) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: `${res.data.MESSAGE}`,
              type: 'error',
              showClose: true,
              duration: 8000,
              offset: 120
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
$bg1:#1890ff;
.wrap {
  .wrap-header {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-input {
    display:flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 15px;
    .input-search {
      width:17%;
      margin-left:24px;
      ::v-deep {
        .el-input__inner{
          text-transform: uppercase !important;
        }
      }
    }
    .el-select {
      width: 17%;
    }
  }
  .wrap-content{
    padding: 5px 24px 24px;
    min-height: 66vh;
    .remarks-width {
      width: 70% !important;
      cursor: pointer;
      display: inline-block !important;
      overflow: hidden !important;
      text-overflow:ellipsis !important;
      white-space: nowrap !important;
    }
    .wrap-content-table {
      width: 100%;
      border-radius: 8px;
      border-top: 1px solid #EBEEF5;
    }
    ::v-deep {
      .title{
        color: #000;
        font-size: 16px;
      }
      .el-table .error-row {
        color: red !important;
      }
      .el-table .success-row {
        color: green !important;
      }
    }
  }
}
</style>
